<template lang="pug">
.date-input-wrapper.w-100
  input-field.countdownFlatpickr-time(
    :value="value"
    :placeholder="placeholder"
    readonly
    :invalid="invalid"
  )
    UilClockEight(slot="icon" size="1.715em")
</template>
<script>
  import moment from 'moment';
  import itemMixin from '@/editor/mixins/item';
  import { UilClockEight } from '@iconscout/vue-unicons';
  import flatpickr from 'flatpickr';
  import 'flatpickr/dist/flatpickr.min.css';
  import sidebarInputMixin from '@/mixins/sidebar/input';

  const FORMAT_12 = 'HH:mm a';
  const FORMAT_24 = 'HH:mm';

  export default {
    components: {
      UilClockEight,
      InputField: () => import('@/editor/components/sidebar/components/InputField.vue'),
    },
    mixins: [itemMixin, sidebarInputMixin],
    props: {
      property: { type: String, required: true },
      withDate: { type: Boolean, default: true },
    },
    data: () => ({
      flatpickrInstance: null,
    }),
    computed: {
      isHu() {
        return this.$i18n.locale === 'hu';
      },
      value() {
        const propValue = this.smartGetValueOf(this.property);
        return propValue ? moment(propValue).format(this.isHu ? FORMAT_24 : FORMAT_12) : '';
      },
      placeholder() {
        return this.isHu ? '00:00' : '12:00 AM';
      },
    },
    mounted() {
      setTimeout(() => {
        this.initFlatpickr();
      }, 400);
    },
    beforeDestroy() {
      if (this.flatpickrInstance !== null) {
        this.flatpickrInstance.close();
        this.flatpickrInstance.destroy();
      }
      this.$store.commit('changeCalendarVisibility', 'hidden');
    },
    methods: {
      reInitFlatpickr() {
        if (this.flatpickrInstance) {
          this.flatpickrInstance.close();
          this.flatpickrInstance.destroy();
        }
        this.initFlatpickr();
      },
      initFlatpickr() {
        const updateFn = function (dates) {
          let date = dates[0];
          if (this.withDate) {
            const propValue = this.smartGetValueOf('data.countdown.endDate.date');
            if (propValue) {
              const parsed = moment(propValue).toDate();
              if (parsed) {
                parsed.setHours(date.getHours());
                parsed.setMinutes(date.getMinutes());
                date = parsed;
              }
            }
          }
          this.updateData({
            property: 'selectedElement.data.countdown.endDate.date',
            value: date.toUTCString(),
          });
        }.bind(this);
        this.flatpickrInstance = flatpickr('.countdownFlatpickr-time', {
          noCalendar: true,
          enableTime: true,
          time_24hr: this.isHu,
          // minDate: 'today',
          defaultDate: this.smartGetValueOf('data.countdown.endDate.date'),
          maxDate: new Date().fp_incr(99),
          dateFormat: 'YYYY/MM/DD H:i',
          onValueUpdate: updateFn,
          onChange: updateFn,
          onOpen: this.$store.commit('changeCalendarVisibility', 'visible'),
        });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .countdownFlatpickr-time
    ::v-deep.epic-simple-input
      input
        position: absolute
      .epic-simple-input-icon
        position: relative
        display: flex
        justify-content: flex-end
        top: 6px
        right: 5px
</style>
